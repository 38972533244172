<!-- System: STA
    Purpose: This compoment will display Stripe
            payment information, details and histroy.
-->
<template>
  <div class="mt-10 invoice-history" v-if="invoices && invoices.length">
    <v-row>
      <v-col col="12">
        <h2 class="card-title">Invoices History</h2>
        <p class="card-subtitle">
          You can download your invoice history and save it to you device.
        </p>
      </v-col>
    </v-row>
    <!-- Stripe Payment Histroy Table -->
    <v-simple-table class="mt-5">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Invoice Name</th>
            <th class="text-left">Invoice Date</th>
            <th class="text-left">From</th>
            <th class="text-left">To</th>
            <th class="text-left">Price</th>
            <th class="text-left">Status</th>
            <th class="text-left">Download</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="invoice.id" v-for="invoice in invoices">
            <td
              class="text-left"
              :title="invoice.id"
              v-if="invoice.id.length > 13"
            >
              {{ invoice.id.substring(0, 13) + "..." }}
            </td>
            <td class="text-left" v-else>{{ invoice.id }}</td>
            <td class="text-left">{{ invoice.created_at }}</td>
            <td class="text-left">{{ invoice.start }}</td>
            <td class="text-left">{{ invoice.end }}</td>
            <td class="text-left">{{ invoice.total }}</td>
            <td class="text-left">
              <span class="badge badge-success" v-if="invoice.paid">Paid</span>
              <span class="badge badge-danger" v-else>Not Paid</span>
            </td>
            <!-- Download Invoice Button -->
            <td class="text-left">
              <button
                @click="download_invice(invoice)"
                class="download-invoice-btn"
              >
                Download Invoice
              </button>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "InvoiceHistory",
  computed: mapState("custom", ["selected_company", "invoices"]),
  mounted() {
    this.get_invoices();
  },
  methods: {
    /**
     * This function is resposible for
     * fetching the stripe histroy details
     */
    get_invoices() {
      this.$store.dispatch("custom/get_payment_history", {
        company_id: this.$route.params.id,
      });
    },
    /**
     * This function is resposible for
     * downloading the invoice details
     */
    download_invice(invoice) {
      if (invoice.pdf) {
        let link = document.createElement("a");
        link.href = invoice.pdf;
        link.target = "_blank";
        link.click();
      } else {
        let data = {
          company_id: this.$route.params.id,
          invoice_id: invoice.invoice_id,
        };
        this.$store
          .dispatch("custom/invoice_download", data)
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Staff Timer App Invoice.pdf";
            link.click();
          })
          .catch(() => {});
      }
    },
  },
};
</script>

<style scoped>
.badge-success {
  background-color: #00d96a !important;
  border: 3px solid #00d96a !important;
  border-radius: 5px !important;
}

.download-invoice-btn {
  height: 34px;
  border: 0.75px solid #2758f6 !important;
  border-radius: 3px;
  background-color: #eff5ff;
  box-shadow: 0 2px 4px 0 rgba(165, 165, 165, 0.23);
  color: #2758f6;
  margin-bottom: 0rem !important;
  font-size: 14px;
  white-space: nowrap;
  padding: 7px 15px;
  font-weight: 400;
  cursor: pointer;
}

.download-invoice-btn:hover {
  color: #2758f6 !important;
  background-color: #eff5ff;
  transform: scale(1.01);
}
</style>
